import styled from 'styled-components';

const cdnUrl = process.env.REACT_APP_CDN_URL;

export const ThirdContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ThirdSubContianer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  h1 {
    font-size: 40px;
    color: black;
  }
`;
export const ThirdHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    width: 546px;
    font-size: 24px;
    color: #000000;
    word-break: break-all;
    font-weight: 400;
    line-height: normal;
  }
`;
export const ThirdImageContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  height: 45vh;
`;

const BaseImage = styled.div`
  width: 350px;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const TextOverlay = styled.h1`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
`;

export const FirstImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route1.png');
  border-radius: 70px 0px 0px 0px;
`;

export const SecondImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route2.png');
`;

export const ThirdImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route3.png');
`;

export const LastImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route4.png');
  border-radius: 0px 0px 70px 0px;
`;
