import React from 'react';
import styled from 'styled-components';
import MainLogo from '../../images/Mainlogo';
import FontLogo from '../../images/FontLogo';
import AsianoBateFooterLogo from '../../images/AsianoBateFooterLogo';
const cdnUrl = process.env.REACT_APP_CDN_URL;
const Footer = () => {
  const handleClick = () => {
    window.open('https://eat-da.com', '_blank');
  };
  return (
    <FootWrapper>
      <BackgroundImageBox>
        <TextBox>
          <Title>세상의 모든 구조를 다시 바라봅니다.</Title>
          <SubTitle>“식자재를 잇다"</SubTitle>
          <Button onClick={handleClick}>바로가기</Button>
        </TextBox>
      </BackgroundImageBox>
      <FooterContainer>
        <FooterSubContainer>
          <div>
            <h2>(주)아시아노베이트</h2>
            <FooterSubContainerItem2>
              <p>
                {' '}
                <span>대표 |</span> 강동훈, 김동혁
              </p>
              <p>
                {' '}
                <span>사업자 등록번호 |</span> 136-81-37386
              </p>
              <p>
                {' '}
                <span>주소 | </span> 경상북도 포항시 북구 중흥로 265,
                108호-디65호(죽도동)
              </p>
              <p>
                <span>이메일 |</span> info@asianovate.com
              </p>
            </FooterSubContainerItem2>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <AsianoBateFooterLogo />
            <CopyRight>Copyright © asianovate. rights reserved.</CopyRight>
          </div>
        </FooterSubContainer>
      </FooterContainer>
      {/*<FooterLogo>*/}
      {/*<FooterSubLogo>*/}
      {/*  <MainLogo />*/}
      {/*  <FontLogo />*/}
      {/*</FooterSubLogo>*/}
      {/*</FooterLogo>*/}
    </FootWrapper>
  );
};

export default Footer;

const FootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const BackgroundImageBox = styled.div`
  background-image: url('${cdnUrl}/bg_Footer.png');
  background-position: 100% 00%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 31vh);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #4a3411;
  width: 100%;
  height: 32vh;
  display: flex;
  align-items: center;
  //padding: ;
`;

const FooterSubContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  padding-block: 30px;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    color: #c78f36;
    padding: 10px 0;
    padding-top: 20px;
    margin: 0;
  }
`;

const FooterSubContainerItem2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-top: 20px;
  gap: 10px;

  p {
    font-size: 16px;
    margin: 0;
  }

  span {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
  }
`;

export const CopyRight = styled.span`
  color: #996d3a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
  letter-spacing: -0.3px;
`;
export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 54px;
`;

const Button = styled.button`
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 999px;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
  letter-spacing: -0.483px;
`;

const SubTitle = styled.span`
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 40px */
  letter-spacing: -0.483px;
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
`;
