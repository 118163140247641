import React from 'react';

const ArrowInBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M0.940981 11.3651C0.645616 11.6555 0.641591 12.1304 0.931991 12.4257C1.22239 12.7211 1.69725 12.7251 1.99261 12.4347L0.940981 11.3651ZM13.2013 1.10634C13.2048 0.692139 12.8719 0.353518 12.4577 0.350008L5.70796 0.292799C5.29376 0.289288 4.95514 0.622216 4.95163 1.03642C4.94812 1.45061 5.28104 1.78923 5.69524 1.79275L11.695 1.8436L11.6442 7.84338C11.6407 8.25758 11.9736 8.5962 12.3878 8.59971C12.802 8.60322 13.1406 8.27029 13.1441 7.85609L13.2013 1.10634ZM1.99261 12.4347L12.9772 1.63479L11.9255 0.565175L0.940981 11.3651L1.99261 12.4347Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default ArrowInBtn;
