import React from 'react';
import styled from 'styled-components';
import EatDaLogo from '../../images/EatDaLogo';
import EatDaFont from '../../images/EatDaFont';
import MobileEatDaLogo from '../../images/MobileEatDaLogo';
import ArrowInBtn from '../../images/ArrowInBtn';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const MobileSecond = () => {
  const handleClick = () => {
    window.open('https://eat-da.com', '_blank');
  };

  return (
    <SecondContainer>
      <SecondMain>
        <TextBox>
          <Title>“식자재를 잇다"</Title>
          <Description>장사의 모든 시스템을 한 화면에 담다</Description>
          <ServiceButton onClick={handleClick}>
            <span>바로 가기 </span>
            <ArrowInBtn />
          </ServiceButton>
        </TextBox>
        <EatdaLogoWrap>
          <MobileEatDaLogo />
        </EatdaLogoWrap>
        <Content>
          "식자재를 잇다"에서 견적 · 주문 그리고 시스템까지 <br />한 번에 모두
          무료로 이용해 보세요
        </Content>
      </SecondMain>
    </SecondContainer>
  );
};

export default MobileSecond;

export const SecondContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform-origin: center;
`;

export const SecondMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  position: relative;
  top: 20px;

  h1 {
    font-size: 22px;
    text-align: center;
    margin: 0;
  }
`;

export const EatdaLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 170px;
  margin: 0 auto;
  border-radius: 50px;
  padding: 8px 10px;
`;

export const TextBox = styled.div`
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const Description = styled.span`
  color: #5c6670;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.164px;
`;
const ServiceButton = styled.div`
  background: #6eb3b5;
  width: fit-content;
  padding: 5px 10px;
  //border: 1px solid #4a3411;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  outline: 2px;
  box-shadow: 0 0 4px 0 #4a341180;
`;
const Title = styled.h1`
  display: flex;
  text-align: center;
`;
const Content = styled.div`
  color: #2c2c2c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.181px;
`;
