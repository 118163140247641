import React from 'react';

const EatDaLogo = () => {
  return (
    <svg
      width="405"
      height="147"
      viewBox="0 0 405 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.941 86.5332C101.01 86.4222 101.066 86.3251 101.135 86.2141L130.396 41.6641H31.7224C14.3103 41.6641 0.200195 55.7741 0.200195 73.1862C0.200195 90.5984 14.3103 104.708 31.7224 104.708H89.0228L100.941 86.5332Z"
        fill="url(#paint0_linear_6754_22204)"
      />
      <path
        d="M372.75 41.6641H310.372C309.914 48.0601 307.874 54.4145 304.101 60.1584L274.84 104.708H372.75C390.162 104.708 404.272 90.5984 404.272 73.1862C404.272 55.7741 390.162 41.6641 372.75 41.6641Z"
        fill="url(#paint1_linear_6754_22204)"
      />
      <path
        d="M200.03 6.38207C182.104 -5.39713 158.033 -0.402414 146.254 17.5231L130.395 41.678L101.135 86.2281C101.065 86.3391 101.01 86.4362 100.94 86.5472L89.0225 104.722C89.0225 104.722 96.9308 128.281 107.6 136.411C109.043 137.701 110.597 138.908 112.276 140.004C130.201 151.784 154.273 146.789 166.052 128.863L181.841 104.82L181.813 104.75L181.841 104.709L192.954 87.9069L193.967 86.3668C193.967 86.3668 194.022 86.2697 194.05 86.2281L211.254 60.0336C214.959 54.3313 216.984 48.0185 217.442 41.678C218.427 28.0952 212.225 14.4014 200.03 6.39594V6.38207Z"
        fill="url(#paint2_linear_6754_22204)"
      />
      <path
        d="M292.96 6.38207C275.035 -5.39713 250.949 -0.402414 239.184 17.5231L223.325 41.678L211.269 60.0336C211.269 60.0336 211.213 60.1307 211.186 60.1723L193.982 86.3668C193.871 86.5472 193.76 86.7137 193.649 86.8941L192.969 87.9069L181.925 104.709L181.855 104.82C182.313 106.054 189.264 124.368 197.63 133.497C199.823 135.925 202.348 138.118 205.206 139.991C223.131 151.77 247.217 146.775 258.982 128.85L274.84 104.695L304.101 60.1446C307.875 54.4145 309.914 48.0463 310.372 41.6503C311.357 28.0674 305.155 14.3736 292.96 6.3682V6.38207Z"
        fill="url(#paint3_linear_6754_22204)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6754_22204"
          x1="-4.17018"
          y1="73.1862"
          x2="124.707"
          y2="73.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B8CDB9" />
          <stop offset="1" stop-color="#168D8D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6754_22204"
          x1="432.035"
          y1="73.1862"
          x2="304.128"
          y2="73.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#53ADAE" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6754_22204"
          x1="86.3586"
          y1="160.427"
          x2="221.188"
          y2="-8.11648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BCCCBA" />
          <stop offset="1" stop-color="#6AAEAB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6754_22204"
          x1="334.93"
          y1="3.30199"
          x2="203.86"
          y2="109.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#53ADAE" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EatDaLogo;
