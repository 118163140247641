import React from 'react';
import styled from 'styled-components';
const cdnUrl = process.env.REACT_APP_CDN_URL;

const MobileThird = () => {
  return (
    <ThirdContainer>
      <h1 style={{ fontSize: '1.75rem' }}>아시아노베이트 방향성</h1>
      <MobileThirdImageContainer>
        <MobileFirstImage>
          <TextOverlay>
            다시 <br /> 바라봅니다.
          </TextOverlay>
        </MobileFirstImage>
        <MobileSecondImage>
          <TextOverlay>
            단순하게 <br /> 생각합니다.
          </TextOverlay>
        </MobileSecondImage>
        <MobileThirdImage>
          <TextOverlay>
            새롭게 <br /> 시작합니다.
          </TextOverlay>
        </MobileThirdImage>
        <MobileLastImage>
          <TextOverlay>
            효율적이게 <br /> 만듭니다.
          </TextOverlay>
        </MobileLastImage>
      </MobileThirdImageContainer>
      <Content>
        아시아노베이트의 발전하기 위한 앞으로의 방향성 <br />
        우리는 지속적으로 모든 고객을 위해 <br />
        끊임 없이 노력합니다.
      </Content>
    </ThirdContainer>
  );
};

export default MobileThird;

const ThirdContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  gap: 1.8rem;
  h1 {
    font-size: 28px;
    text-align: center;
    color: black;
    margin-top: 20px;
  }
`;

const MobileThirdImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
  width: 80%;
  height: 55vh;
  padding: 0 20px;
`;

const BaseImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const MobileFirstImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route1.png');
  border-radius: 70px 0px 0px 0px;
`;

const MobileSecondImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route2.png');
`;

const MobileThirdImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route3.png');
`;

const MobileLastImage = styled(BaseImage)`
  background-image: url('${cdnUrl}/route4.png');
  border-radius: 0px 0px 70px 0px;
`;

const TextOverlay = styled.h2`
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: white;
  font-size: 18px;
`;
const Content = styled.span`
  color: black;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
