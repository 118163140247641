import React from 'react';

const EatDaFont = () => {
  return (
    <svg
      width="551"
      height="116"
      viewBox="0 0 551 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.0007 65.4272L46.2152 74.709C36.6558 71.7261 30.0933 65.7463 26.3612 58.0739C22.5458 66.3845 15.789 73.0025 5.86899 76.1797L-0.0136719 66.7175C14.0964 62.2639 20.201 51.3726 20.201 40.7172V32.7257H32.3271V40.7172C32.2855 51.1922 38.3485 61.2927 51.9868 65.4411L52.0007 65.4272ZM70.384 112.572H58.4383V91.9963H12.9587V82.534H70.384V112.586V112.572ZM70.384 78.7603H58.4383V28.9519H70.384V78.7603Z"
        fill="url(#paint0_linear_6754_22209)"
      />
      <path
        d="M128.184 85.3644L121.663 94.7295C112.242 90.4562 105.624 81.9514 101.906 71.9481C98.1879 82.9225 91.3895 92.2599 81.6915 96.8522L74.9902 87.4733C88.7673 80.9524 95.8015 65.2884 95.7599 51.553V46.6831H78.209V36.9434H124.424V46.6831H107.608V51.553C107.608 64.234 114.448 79.0655 128.184 85.3644ZM142.793 60.8348H155.1V70.6716H142.793V112.572H130.945V28.9519H142.793V60.8348Z"
        fill="url(#paint1_linear_6754_22209)"
      />
      <path
        d="M194.502 84.5459L187.704 93.5502C180.128 89.4573 175.12 82.6174 172.179 74.0709C169.334 83.9077 164.409 91.8576 156.459 96.5887L149.286 87.4872C161.87 80.2726 166.06 65.5243 166.005 49.4442V46.9607H151.95V37.4013H190.354V46.9607H177.492V49.4442C177.492 63.8733 181.807 77.9279 194.488 84.5459H194.502ZM223.915 112.475H212.608V70.6717H205.629V108.798H194.516V30.2423H205.629V61.0291H212.608V28.9658H223.915V112.489V112.475Z"
        fill="url(#paint2_linear_6754_22209)"
      />
      <path
        d="M304.331 75.0837H227.981V66.44H304.331V75.0837ZM295.23 99.2386H248.46V103.831H297.616V111.823H236.611V91.6078H283.561V87.3762H236.514V79.2875H295.23V99.2248V99.2386ZM295.049 50.0823H249.112V54.4944H296.617V62.4859H237.444V42.5487H283.298V38.4142H237.36V30.4226H295.063V50.0823H295.049Z"
        fill="url(#paint3_linear_6754_22209)"
      />
      <path
        d="M404.946 36C404.946 52.3994 391.391 64.0675 373.535 63.9982C355.624 64.0675 341.999 52.3994 341.999 36C341.999 19.6007 355.624 7.87704 373.535 7.87704C391.447 7.87704 404.946 19.6701 404.946 36ZM442.157 102.596L435.983 115.333C417.378 112.364 404.142 104.164 397.385 93.0091C390.448 104.233 377.142 112.364 358.537 115.333L352.363 102.596C376.837 99.1277 388.88 86.516 388.935 73.835V71.5596H405.834V73.835C405.765 86.4466 417.627 99.1277 442.157 102.596ZM358.024 36C357.954 44.9489 364.586 50.3182 373.535 50.3737C382.428 50.3043 388.991 44.9489 388.922 36C388.991 26.9124 382.428 21.6819 373.535 21.7512C364.586 21.6819 357.954 26.9263 358.024 36ZM436.482 77.248H420.083V0.939941H436.482V77.248Z"
        fill="url(#paint4_linear_6754_22209)"
      />
      <path
        d="M501.927 24.6508H464.217V74.7228C480.935 74.5286 494.615 73.71 509.627 71.1849L511.264 84.6845C493.727 87.7091 478.216 88.3473 457.654 88.3473H447.942V11.4149H501.927V24.6647V24.6508ZM534.101 43.3255H551V56.8251H534.101V115.985H517.827V0.828857H534.101V43.3394V43.3255Z"
        fill="url(#paint5_linear_6754_22209)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6754_22209"
          x1="-87.768"
          y1="70.7549"
          x2="364.198"
          y2="70.7549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6754_22209"
          x1="-87.768"
          y1="-174"
          x2="364.198"
          y2="-174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6754_22209"
          x1="-87.7685"
          y1="70.7133"
          x2="364.198"
          y2="70.7133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6754_22209"
          x1="-87.7676"
          y1="71.1295"
          x2="364.198"
          y2="71.1295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6754_22209"
          x1="-87.7682"
          y1="58.1433"
          x2="364.198"
          y2="58.1433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6754_22209"
          x1="-87.7685"
          y1="58.3929"
          x2="364.198"
          y2="58.3929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EatDaFont;
