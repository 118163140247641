import React from 'react';

const FontLogo = () => {
  return (
    <svg
      width="169"
      height="14"
      viewBox="0 0 169 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9485 13.1673H10.7781L9.27568 9.37757H3.22248L1.70263 13.1673H0.523438L5.64201 0.314453H6.89109L11.9573 13.1673H11.9485ZM8.89135 8.41106L6.27091 1.73878L3.58934 8.41106H8.89135Z"
        fill="white"
      />
      <path
        d="M21.6268 11.6832C22.2033 12.1241 22.8496 12.353 23.5659 12.3615C24.4918 12.37 25.2517 12.1495 25.8282 11.6917C26.4047 11.2339 26.7017 10.615 26.7017 9.85197C26.7017 9.34329 26.5619 8.9109 26.2824 8.57178C26.0116 8.23265 25.6622 7.95288 25.2517 7.75788C24.8412 7.56288 24.2821 7.32549 23.5746 7.06267C22.7536 6.76594 22.0985 6.48616 21.6006 6.23181C21.1027 5.97747 20.6922 5.61291 20.3428 5.13814C19.9934 4.66336 19.8274 4.05294 19.8274 3.32382C19.8274 2.67948 20.0021 2.11992 20.3515 1.63667C20.7009 1.15342 21.1726 0.78886 21.784 0.534516C22.3867 0.280173 23.0593 0.153 23.8105 0.169957C24.3957 0.178435 24.9373 0.28865 25.4351 0.500603C25.933 0.712556 26.361 1.00929 26.7279 1.40776C27.0947 1.80623 27.3655 2.28101 27.549 2.84057L26.5357 3.17969C26.3523 2.57774 26.0116 2.08601 25.4963 1.7045C24.9897 1.32298 24.4132 1.12798 23.7843 1.11951C22.9545 1.11951 22.2644 1.32298 21.7316 1.72145C21.1988 2.1284 20.928 2.66252 20.928 3.34078C20.928 3.8749 21.0678 4.32424 21.3473 4.68032C21.6268 5.0364 21.9849 5.31618 22.4042 5.52813C22.8234 5.74008 23.3912 5.96899 24.0987 6.23181C24.8936 6.52007 25.5312 6.79137 26.0204 7.04572C26.5095 7.30006 26.9288 7.65614 27.2694 8.11396C27.6101 8.57178 27.7848 9.16525 27.7848 9.88589C27.7848 10.5641 27.6101 11.1661 27.252 11.6917C26.9026 12.2174 26.396 12.6243 25.7583 12.9126C25.1207 13.2008 24.3782 13.3365 23.531 13.3195C22.64 13.311 21.8277 13.0567 21.1114 12.5735C20.3864 12.0817 19.7663 11.395 19.2422 10.5048L20.1943 10.0046C20.5611 10.6913 21.0328 11.2593 21.618 11.6917L21.6268 11.6832Z"
        fill="white"
      />
      <path
        d="M38.2939 0.305176V13.158H37.1934V0.305176H38.2939Z"
        fill="white"
      />
      <path
        d="M59.0813 13.1673H57.9109L56.4085 9.37757H50.3553L48.8354 13.1673H47.6562L52.7748 0.314453H54.0239L59.0901 13.1673H59.0813ZM56.0154 8.41106L53.395 1.73878L50.7134 8.41106H56.0154Z"
        fill="white"
      />
      <path
        d="M77.7394 13.1673H76.6388L69.1182 2.21355V13.1673H68.0176V0.314453H69.1182L76.6388 11.2258V0.314453H77.7394V13.1673Z"
        fill="white"
      />
      <path
        d="M96.6929 1.00096C97.6799 1.56051 98.4398 2.33202 98.9901 3.33244C99.5404 4.33286 99.8112 5.46893 99.8112 6.73217C99.8112 7.99541 99.5404 9.13148 98.9901 10.1319C98.4398 11.1323 97.6712 11.9038 96.6929 12.4634C95.7146 13.0229 94.5878 13.3027 93.3475 13.3027C92.1071 13.3027 90.9803 13.0229 90.002 12.4634C89.015 11.9038 88.2464 11.1323 87.6961 10.1319C87.1458 9.13148 86.875 7.99541 86.875 6.73217C86.875 5.46893 87.1458 4.32438 87.6961 3.33244C88.2464 2.3405 89.015 1.56051 90.002 1.00096C90.9803 0.441399 92.0984 0.161621 93.3475 0.161621C94.5965 0.161621 95.7059 0.441399 96.6929 1.00096ZM96.1251 11.641C96.9375 11.1578 97.5664 10.488 98.0206 9.64017C98.4748 8.78387 98.7019 7.81737 98.7019 6.74065C98.7019 5.66392 98.4748 4.68894 98.0206 3.84113C97.5664 2.98484 96.9375 2.31507 96.1251 1.84029C95.3128 1.35704 94.3869 1.11965 93.3475 1.11965C92.308 1.11965 91.3822 1.35704 90.5698 1.84029C89.7575 2.32354 89.1286 2.98484 88.6744 3.84113C88.2202 4.69742 87.9931 5.66392 87.9931 6.74065C87.9931 7.81737 88.2202 8.79235 88.6744 9.64017C89.1286 10.4965 89.7575 11.1662 90.5698 11.641C91.3822 12.1243 92.308 12.3616 93.3475 12.3616C94.3869 12.3616 95.3128 12.1243 96.1251 11.641Z"
        fill="white"
      />
      <path
        d="M113.42 13.1673H112.25L107.219 0.314453H108.398L112.818 11.6412L117.037 0.314453H118.207L113.42 13.1673Z"
        fill="white"
      />
      <path
        d="M135.721 13.1673H134.542L133.039 9.37757H126.986L125.466 13.1673H124.296L129.414 0.314453H130.664L135.73 13.1673H135.721ZM132.664 8.41106L130.043 1.73878L127.371 8.41106H132.673H132.664Z"
        fill="white"
      />
      <path
        d="M152.027 1.25552H147.974V13.1673H146.873V1.25552H142.855V0.314453H152.027V1.25552Z"
        fill="white"
      />
      <path
        d="M169 1.25552H162.029V6.06262H168.065V7.02913H162.029V12.2262H169V13.1673H160.929V0.314453H169V1.25552Z"
        fill="white"
      />
    </svg>
  );
};

export default FontLogo;
