import React from 'react';
import styled from 'styled-components';
import MobileAsianobateFont from '../../images/MobileAsianobateFont';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import '../swipreStyle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const IMAGE_MARGIN = 48;
const cdnUrl = process.env.REACT_APP_CDN_URL;
const images = [
  {
    src: `${cdnUrl}/d_slide_img_1.png`,
    text: '직급이 없는 편안한 분위기의 사무실',
    top: true,
  },
  {
    src: `${cdnUrl}/d_slide_img_2.png`,
    text: '자유로운 분위기와 일할 때의 명확한 소통이 가능한 회사',
    top: false,
  },
  {
    src: `${cdnUrl}/d_slide_img_4.png`,
    text: '새로운 아이디어와 변화를 환영하며, \n 도전 정신을 북돋우는 환경',
    top: true,
  },
  {
    src: `${cdnUrl}/d_slide_img_3.png`,
    text: '행복한 근무 환경을 제공하는 회사',
    top: false,
  },
];

const MobileFifth = () => {
  const displayImages = images;
  return (
    <FifthContainer>
      <div style={{ marginBottom: '32px' }}>
        <MobileAsianobateFont />
        <h1>
          내부 <br />
          분위기
        </h1>
      </div>
      <MobileThirdImageContainer>
        <Swiper
          spaceBetween={36}
          slidesPerView={1.5}
          // loop={true}
          freeMode={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          <div>
            {displayImages.map((image, index) => (
              <SwiperSlide>
                <Card>
                  <SlideImage key={index} $imageUrl={image.src}></SlideImage>
                  <p style={{ color: 'black', whiteSpace: 'pre-line' }}>
                    {image.text}
                  </p>
                </Card>
              </SwiperSlide>
            ))}
          </div>
          ...
        </Swiper>
      </MobileThirdImageContainer>
    </FifthContainer>
  );
};
export default MobileFifth;

const FifthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 22px;
    text-align: center;
    color: black;
    margin: 10px 0;
  }
`;

const MobileThirdImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  position: relative;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: auto;
  height: 470px;
  margin-bottom: 4.25rem;
  p {
    color: #2c2c2c;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: pre-line;
  }
`;
const SlideImage = styled.div`
  width: auto;
  height: 300px;
  flex-shrink: 0;
  background-image: url(${(props) => props.$imageUrl});
  background-size: cover;
  background-position: center;

  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
`;
