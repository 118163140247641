import React from 'react';
import styled from 'styled-components';

const MobileHeader = ({ textColor = 'white' }) => {
  return (
    <MHeaderContainer>
      <HeaderItemContainer>
        <HeaderItem textColor={textColor}>회사 소개</HeaderItem>
        <HeaderItem textColor={textColor}>서비스 소개</HeaderItem>
        <HeaderItem textColor={textColor}>경영 방침</HeaderItem>
        <HeaderItem textColor={textColor}>인력 채용</HeaderItem>
      </HeaderItemContainer>
    </MHeaderContainer>
  );
};

export default MobileHeader;

const MHeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  z-index: 1000;
`;

const HeaderItemContainer = styled.div`
  display: flex;
`;

const HeaderItem = styled.div`
  font-size: 16px;
  padding: 5px 12px;
  color: ${(props) => props.textColor};
`;
