import styled from 'styled-components';

const FullPageSection = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.7s ease-in-out;
  transform: translateY(-${(props) => props.currentPage * 100}%);
`;

export default FullPageSection;
