const BrownMainLogo = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0527344 31.0159C0.0527344 31.0159 3.16421 8.18626 9.37821 1.38971C9.99514 0.715381 10.7551 0.635525 11.229 1.41633C12.588 3.66114 14.26 8.75412 14.9574 12.7912C14.9574 12.7912 12.5523 4.92995 10.8445 2.9247C10.5137 2.5343 10.1203 2.52543 9.73585 2.85372C7.20554 5.0098 3.01221 16.1806 0.0527344 31.0159Z"
        fill="#4A3411"
      />
      <path
        d="M0 31.1759C0 31.1759 17.122 3.02255 26.4833 5.36497C27.7976 5.69326 28.5665 6.88221 28.6559 8.222C28.7543 9.73037 28.0837 12.0905 24.6236 15.1516C24.6236 15.1516 29.2371 9.26899 26.5101 7.34359C22.9873 4.85034 12.4459 14.0603 0 31.167L0 31.1759Z"
        fill="#4A3411"
      />
      <path
        d="M0.207031 30.9537C0.207031 30.9537 20.9681 12.2677 28.4606 15.6837C29.775 16.2782 30.4992 17.7511 30.0432 19.1086C29.623 20.3685 28.3533 22.0455 24.9289 23.6248C24.9289 23.6248 30.3293 19.8362 28.5858 17.547C26.3237 14.5835 14.6646 19.6765 0.207031 30.9449V30.9537Z"
        fill="#4A3411"
      />
      <path
        d="M0.303863 30.998C0.303863 30.998 20.0367 19.6586 25.3029 23.6513C26.2239 24.3523 26.501 25.6743 25.8841 26.6503C25.3119 27.5642 24.0065 28.6467 21.0828 29.321C21.0828 29.321 26.0093 27.227 25.0347 25.1331C23.774 22.4269 13.474 25.0709 0.294922 31.0068L0.303863 30.998Z"
        fill="#4A3411"
      />
    </svg>
  );
};

export default BrownMainLogo;
