import React from 'react';
import MobileFirst from '../../mobile/first/MobileFirst';
import MobileFullPage from '../Fullpage/MobileFullpage';
import MobileSecond from '../../mobile/second/MobileSecond';
import MobileThird from '../../mobile/third/MobileThird';
import MobileFourth from '../../mobile/fourth/MobileFourth';
import MobileFifth from '../../mobile/fifth/MobileFifth';
import MobileFooter from '../../mobile/footer/MobileFooter';

const Mobile = () => {
  return (
    <MobileFullPage>
      <MobileFirst showHeader={true} headerTextColor="white" />
      <MobileSecond showHeader={true} headerTextColor="black" />
      <MobileThird showHeader={true} headerTextColor="black" />
      <MobileFourth showHeader={true} headerTextColor="white" />
      <MobileFifth showHeader={true} headerTextColor="black" />
      <MobileFooter showHeader={true} headerTextColor="white" />
    </MobileFullPage>
  );
};

export default Mobile;
