import React, { Fragment } from 'react';
import Fifth from '../../page/fifth/Fifth';
import First from '../../page/first/First';
import Footer from '../../page/footer/Footer';
import Fourth from '../../page/fourth/Fourth';
import Second from '../../page/second/Second';
import Third from '../../page/third/Third';
import FullPage from '../Fullpage/Fullpage';
import {
  FirstHeader,
  HeaderLeft,
  HeaderRight,
} from '../../page/first/First.styled';
import MainLogo from '../../images/Mainlogo';
import FontLogo from '../../images/FontLogo';

const Desktop = () => {
  return (
    <Fragment>
      <FullPage>
        <First />
        <Second />
        <Third />
        <Fourth />
        <Fifth />
        <Footer />
      </FullPage>
    </Fragment>
  );
};

export default Desktop;
