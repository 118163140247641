import React, { useEffect, useState } from 'react';
import MobileHeader from '../../mobile/MobileHeader/MobileHeader';
import MobilePageWrapper from './MobilePageWrapper';
import MobilePageSection from './MobilePageSection';

const MobileFullPage = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [headerTextColor, setHeaderTextColor] = useState('white');

  const minSwipeDistance = 50;

  useEffect(() => {
    const updateVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateVh();
    window.addEventListener('resize', updateVh);
    window.addEventListener('orientationchange', updateVh);

    return () => {
      window.removeEventListener('resize', updateVh);
      window.removeEventListener('orientationchange', updateVh);
    };
  }, []);
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe && currentPage < React.Children.count(children) - 1) {
      setCurrentPage((prev) => prev + 1);
    } else if (isDownSwipe && currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const currentChild = React.Children.toArray(children)[currentPage];
    const newShowHeader = currentChild.props.showHeader !== false;
    const newHeaderTextColor = currentChild.props.headerTextColor || 'white';
    console.log('newHeaderTextColor', newHeaderTextColor);
    setShowHeader(newShowHeader);
    setHeaderTextColor(newHeaderTextColor);
  }, [currentPage, children]);

  return (
    <MobilePageWrapper
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {showHeader && <MobileHeader textColor={headerTextColor} />}
      {React.Children.map(children, (child, index) => (
        <MobilePageSection key={index} currentPage={currentPage}>
          {React.cloneElement(child, {
            isActive: index === currentPage,
            currentPage: currentPage,
          })}
        </MobilePageSection>
      ))}
    </MobilePageWrapper>
  );
};

export default MobileFullPage;
