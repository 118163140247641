import styled from 'styled-components';
import { useEffect, useState } from 'react';

const FullPageWrapper = styled.div`
  height: ${(props) => props.height}px;
  overflow: hidden;
  touch-action: none;
`;

const MobilePageWrapper = ({ children, ...props }) => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <FullPageWrapper height={height} {...props}>
      {children}
    </FullPageWrapper>
  );
};

export default MobilePageWrapper;
