import React from 'react';
import styled from 'styled-components';
import MobileFooterLogo from '../../images/MobileFooter';

const cdnUrl = process.env.REACT_APP_CDN_URL;
const MobileFooter = () => {
  const handleClick = () => {
    window.open('https://eat-da.com', '_blank');
  };
  return (
    <FooterSubContainer>
      {' '}
      {/*<TextBox>*/}
      {/*  <Title>*/}
      {/*    세상의 모든 <br /> 구조를 다시 바라봅니다.*/}
      {/*  </Title>*/}
      {/*  <SubTitle>“식자재를 잇다"</SubTitle>*/}
      {/*  <Button>바로가기</Button>*/}
      {/*</TextBox>*/}
      <BackgroundImageBox>
        <TextBox>
          <Title>
            세상의 모든 <br /> 구조를 다시 바라봅니다.
          </Title>
          <SubTitle>“식자재를 잇다"</SubTitle>
          <Button onClick={handleClick}>바로가기</Button>
        </TextBox>
      </BackgroundImageBox>
      <FooterInner>
        <FooterSubContainerItem>
          <h2>(주)아시아노베이트</h2>
          <ContentWrapper>
            <p>
              {' '}
              <span>대표 |</span> 강동훈, 김동혁
            </p>
            <p>
              {' '}
              <span>사업자 등록번호 |</span> 136-81-37386
            </p>
            <p>
              {' '}
              <span>주소 | </span> 경상북도 포항시 북구 중흥로 265,
              108호-디65호(죽도동)
            </p>
            <p>
              <span>이메일 |</span> info@asianovate.com
            </p>
          </ContentWrapper>
          <Divider>&nbsp;</Divider>
        </FooterSubContainerItem>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <MobileFooterLogo />
          <CopyRight>Copyright © asianovate. rights reserved.</CopyRight>
        </div>
      </FooterInner>
    </FooterSubContainer>
  );
};

export default MobileFooter;

const FooterSubContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${cdnUrl}/bg_Footer.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const FooterInner = styled.div`
  display: flex;
  padding: 24px 20px 20px 20px;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background: #4a3411;
  left: 0;
  width: 90%;
  bottom: 0;
  height: 45dvh;
  gap: 16px;
`;

const FooterSubContainerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  h2 {
    font-size: 15px;
    color: #c78f36;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 13px;
  }

  span {
    font-weight: bold;
    margin: 0;
    font-size: 13px;
  }

  h6 {
    margin: 0;
    font-size: 15px;
  }
`;
export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;
`;
const BackgroundImageBox = styled.div`
  background-image: url('${cdnUrl}/bg_Footer.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 30dvh);
  display: flex;
  justify-content: center;

  //align-items: center;
`;
const SubTitle = styled.span`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.206px;
`;

const TextBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  //justify-content: center;
  gap: 12px;
`;
const Button = styled.button`
  border-radius: 20px;
  border: 1px solid #fff;
  width: 85px;
  padding: 4px 12px;
  //height: 23px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.1);
  color: white;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.25);
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`;

const CopyRight = styled.p`
  color: #996d3a;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 10px */
  letter-spacing: -0.16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
