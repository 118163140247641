import React from 'react';

const MobileGraphIcon = () => {
  return (
    <svg
      width="48"
      height="47"
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.26809"
        y="0.697775"
        width="45.1228"
        height="45.1228"
        rx="8.60589"
        stroke="#4A3411"
        stroke-width="1.39555"
      />
      <g clip-path="url(#clip0_5050_13178)">
        <path
          d="M35.1722 13.7718H31.5235C31.2897 13.7718 31.0612 13.8412 30.8668 13.9711C30.6724 14.1011 30.5209 14.2857 30.4314 14.5017C30.342 14.7177 30.3185 14.9554 30.3641 15.1847C30.4097 15.414 30.5223 15.6247 30.6876 15.79L31.9716 17.0741L27.7318 21.3128C27.5065 21.5281 27.2069 21.6482 26.8952 21.6482C26.5836 21.6482 26.284 21.5281 26.0587 21.3128L25.8577 21.1106C25.1822 20.4651 24.2838 20.1049 23.3494 20.1049C22.415 20.1049 21.5166 20.4651 20.8411 21.1106L14.8111 27.1405C14.589 27.3633 14.4644 27.6652 14.4648 27.9799C14.4653 28.2945 14.5907 28.5961 14.8135 28.8183C15.0363 29.0404 15.3382 29.165 15.6529 29.1645C15.9675 29.1641 16.2691 29.0387 16.4912 28.8159L22.5212 22.786C22.7463 22.5704 23.046 22.4501 23.3577 22.4501C23.6694 22.4501 23.9691 22.5704 24.1942 22.786L24.3952 22.9881C25.071 23.6331 25.9693 23.9929 26.9035 23.9929C27.8377 23.9929 28.736 23.6331 29.4119 22.9881L33.6517 18.7483L34.9358 20.0323C35.1017 20.1949 35.3118 20.3051 35.5399 20.349C35.7681 20.3929 36.0041 20.3686 36.2185 20.2792C36.4329 20.1898 36.6163 20.0391 36.7456 19.8461C36.8749 19.6531 36.9445 19.4263 36.9457 19.194V15.5453C36.9457 15.0749 36.7589 14.6238 36.4263 14.2912C36.0937 13.9586 35.6426 13.7718 35.1722 13.7718Z"
          fill="#4A3411"
        />
        <path
          d="M35.7641 35.0547H14.482C13.5413 35.0547 12.6391 34.6809 11.9739 34.0158C11.3087 33.3506 10.935 32.4484 10.935 31.5076V10.2539C10.935 9.9403 10.8104 9.63956 10.5887 9.41783C10.367 9.1961 10.0662 9.07153 9.75265 9.07153C9.43908 9.07153 9.13834 9.1961 8.91661 9.41783C8.69488 9.63956 8.57031 9.9403 8.57031 10.2539V31.5076C8.57219 33.0749 9.19563 34.5775 10.3039 35.6858C11.4121 36.794 12.9147 37.4175 14.482 37.4193H35.7641C36.0777 37.4193 36.3785 37.2948 36.6002 37.073C36.8219 36.8513 36.9465 36.5506 36.9465 36.237C36.9465 35.9234 36.8219 35.6227 36.6002 35.4009C36.3785 35.1792 36.0777 35.0547 35.7641 35.0547Z"
          fill="#4A3411"
        />
      </g>
      <defs>
        <clipPath id="clip0_5050_13178">
          <rect
            width="28.3762"
            height="28.3762"
            fill="white"
            transform="translate(8.57031 9.07104)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileGraphIcon;
