import React from 'react';
import {
  ThirdContainer,
  ThirdSubContianer,
  ThirdImageContainer,
  TextOverlay,
  FirstImage,
  SecondImage,
  ThirdImage,
  LastImage,
  ThirdHeader,
} from './Third.Styled';
import styled from 'styled-components';

const Third = () => {
  return (
    <ThirdContainer>
      <ThirdSubContianer>
        <ThirdHeader>
          <h1> 방향성</h1>
          <p>
            아시아노베이트의 발전하기 위한 앞으로의 방향성
            <br />
            우리는 지속적으로 모든 고객을 위해 끊임 없이 노력합니다.
          </p>
        </ThirdHeader>
      </ThirdSubContianer>
      <ThirdImageContainer>
        <FirstImage>
          <TextOverlay>
            다시 <br /> 바라봅니다.
          </TextOverlay>
        </FirstImage>
        <SecondImage>
          <TextOverlay>
            단순하게 <br /> 생각합니다.
          </TextOverlay>
        </SecondImage>
        <ThirdImage>
          <TextOverlay>
            새롭게 <br /> 시작합니다.
          </TextOverlay>
        </ThirdImage>
        <LastImage>
          <TextOverlay>
            효율적이게 <br /> 만듭니다.
          </TextOverlay>
        </LastImage>
      </ThirdImageContainer>
    </ThirdContainer>
  );
};

export default Third;
