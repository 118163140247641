const GraphIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="97"
        height="97"
        rx="18.5"
        stroke="#4A3411"
        stroke-width="3"
      />
      <path
        d="M76.1873 30.1055H68.3437C67.8411 30.1056 67.3498 30.2547 66.9319 30.534C66.5141 30.8133 66.1884 31.2102 65.996 31.6746C65.8037 32.1389 65.7534 32.6499 65.8514 33.1428C65.9494 33.6358 66.1914 34.0886 66.5468 34.4441L69.307 37.2043L60.1926 46.3162C59.7083 46.779 59.0642 47.0373 58.3944 47.0373C57.7245 47.0373 57.0804 46.779 56.5961 46.3162L56.1641 45.8816C54.7118 44.494 52.7805 43.7197 50.7719 43.7197C48.7633 43.7197 46.832 44.494 45.3798 45.8816L32.4173 58.8441C31.9397 59.323 31.6719 59.9721 31.6729 60.6485C31.6738 61.3248 31.9434 61.9731 32.4224 62.4507C32.9013 62.9283 33.5503 63.1961 34.2267 63.1951C34.9031 63.1942 35.5514 62.9246 36.029 62.4456L48.9915 49.4831C49.4755 49.0197 50.1196 48.761 50.7897 48.761C51.4598 48.761 52.104 49.0197 52.5879 49.4831L53.02 49.9178C54.4729 51.3042 56.4039 52.0777 58.4122 52.0777C60.4204 52.0777 62.3514 51.3042 63.8043 49.9178L72.9187 40.8033L75.679 43.5636C76.0356 43.9132 76.4874 44.15 76.9778 44.2444C77.4682 44.3388 77.9756 44.2866 78.4365 44.0943C78.8975 43.9021 79.2916 43.5783 79.5696 43.1634C79.8476 42.7486 79.9973 42.261 79.9998 41.7616V33.918C79.9998 32.9068 79.5981 31.9371 78.8832 31.2221C78.1682 30.5071 77.1984 30.1055 76.1873 30.1055Z"
        fill="#4A3411"
      />
      <path
        d="M77.4583 75.8557H31.7083C29.6861 75.8557 27.7466 75.0523 26.3166 73.6224C24.8867 72.1924 24.0833 70.2529 24.0833 68.2307V22.5417C24.0833 21.8676 23.8156 21.2211 23.3389 20.7444C22.8622 20.2678 22.2158 20 21.5417 20C20.8676 20 20.2211 20.2678 19.7444 20.7444C19.2678 21.2211 19 21.8676 19 22.5417V68.2307C19.004 71.5999 20.3442 74.83 22.7266 77.2124C25.109 79.5948 28.3391 80.935 31.7083 80.939H77.4583C78.1324 80.939 78.7789 80.6712 79.2556 80.1946C79.7322 79.7179 80 79.0714 80 78.3973C80 77.7232 79.7322 77.0768 79.2556 76.6001C78.7789 76.1234 78.1324 75.8557 77.4583 75.8557Z"
        fill="#4A3411"
      />
    </svg>
  );
};

export default GraphIcon;
