import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import ArrowRight from '../../images/ArrowRight';
import ArrowLeft from '../../images/ArrowLeft';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const IMAGE_WIDTH = 450;
const IMAGE_MARGIN = 48;
const TOTAL_WIDTH = IMAGE_WIDTH + IMAGE_MARGIN;
const images = [
  {
    src: `${cdnUrl}/d_slide_img_1.png`,
    text: '직급이 없는 편안한 분위기의 사무실',
    top: true,
  },
  {
    src: `${cdnUrl}/d_slide_img_2.png`,
    text: '자유로운 분위기와 일할 때의 명확한 소통이 \n 가능한 회사',
    top: false,
  },
  {
    src: `${cdnUrl}/d_slide_img_4.png`,
    text: '새로운 아이디어와 변화를 환영하며, \n 도전 정신을 북돋우는 환경',
    top: true,
  },
  {
    src: `${cdnUrl}/d_slide_img_3.png`,
    text: '행복한 근무 환경을 제공하는 회사',
    top: false,
  },
];

const Fifth = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sliderRef = useRef(null);

  const displayImages = images;

  const moveSlide = (direction) => {
    if (
      (currentIndex === 0 && direction === -2) ||
      (currentIndex === displayImages.length && direction === 2)
    ) {
      return;
    }
    setIsTransitioning(true);
    console.log('direction', currentIndex);

    setCurrentIndex((prevIndex) => prevIndex + direction);
  };

  const handleClick = (direction) => {
    moveSlide(direction);
  };
  const handleRangeChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    if (newIndex !== currentIndex) {
      setIsTransitioning(true);
      setCurrentIndex(newIndex);
    }
  };
  return (
    <ThirdContainer>
      <Wrapper>
        <InnerWrapper>
          <ThirdSubContainer>
            <div>
              <h1>
                내부 <br /> 분위기
              </h1>
              <ArrowRight color={'black'} />
            </div>
          </ThirdSubContainer>
          <ThirdImageContainer>
            <ImageSlider
              ref={sliderRef}
              $translate={-currentIndex * TOTAL_WIDTH}
              $isTransitioning={isTransitioning}
            >
              {displayImages.map((image, index) => (
                <Card onDrag={handleRangeChange}>
                  <SlideImage key={index} $imageUrl={image.src}></SlideImage>
                  <p>{image.text}</p>
                </Card>
              ))}
            </ImageSlider>
          </ThirdImageContainer>
        </InnerWrapper>
        <BottomWrapper>
          <CustomScrollBar>
            <ScrollRange
              type="range"
              min="0"
              max={displayImages.length - 2}
              step="1"
              value={currentIndex}
              onChange={handleRangeChange}
            />
          </CustomScrollBar>
          <div style={{ display: 'flex', gap: '48px', marginLeft: '90px' }}>
            <ArrowButton
              onClick={() => handleClick(-2)}
              disabled={currentIndex === 0}
              aria-disabled={currentIndex === 0}
            >
              <ArrowLeft color={currentIndex === 0 ? '#eaeaea' : '#000'} />
            </ArrowButton>
            <ArrowButton
              onClick={() => handleClick(2)}
              disabled={currentIndex === 2}
              aria-disabled={currentIndex === displayImages.length - 1}
            >
              <ArrowRight
                color={
                  currentIndex === displayImages.length - 1 ? '#eaeaea' : '#000'
                }
              />
            </ArrowButton>
          </div>
        </BottomWrapper>
      </Wrapper>
    </ThirdContainer>
  );
};

export default Fifth;

const ThirdContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  //margin: 0 auto;
`;
const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  flex-direction: column;
  //justify-content: space-between;
  align-items: flex-start;
`;
const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 170px;
  //justify-content: space-between;
  align-items: flex-start;
`;
const ThirdSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  h1 {
    font-size: 40px;
    margin-bottom: 20px;
    color: black;
  }
`;

const ThirdImageContainer = styled.div`
  width: ${TOTAL_WIDTH * 2}px;
  height: 100%;
  overflow: hidden;
  margin-block: auto;
  margin-left: auto;
`;

const ImageSlider = styled.div`
  display: flex;
  height: 700px;
  transform: translateX(${(props) => props.$translate}px);
  transition: ${(props) =>
    props.$isTransitioning ? 'transform 0.5s ease' : 'none'};
  will-change: transform;
`;

const SlideImage = styled.div`
  width: ${IMAGE_WIDTH}px;
  height: 80%;
  flex-shrink: 0;
  background-image: url(${(props) => props.$imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-right: ${IMAGE_MARGIN}px;
`;
const Card = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 680px;
  p {
    color: #2c2c2c;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: pre-line;
  }
`;

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const CustomScrollBar = styled.div`
  width: 70%;
  margin-right: 24px;
  border-radius: 50%;
`;

const ArrowButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const ScrollRange = styled.input`
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  /* 트랙 스타일 */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: #dedede;
    border: none;
    border-radius: 16px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    background: #dedede;
    border: none;
    border-radius: 2px;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    background: transparent;
    border-color: transparent;
    color: transparent;
    border-radius: 100%;
  }

  /* 썸 스타일 */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50%;
    height: 4px;
    background: #000;
    cursor: pointer;
    position: relative;
    border-radius: 32px;
  }

  /* 포커스 스타일 제거 */
  &:focus {
    outline: none;
  }
`;
