import styled from 'styled-components';

const cdnUrl = process.env.REACT_APP_CDN_URL;

// 배경 이미지를 넣기 위한 컨테이너
export const FirstContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url('${cdnUrl}/first.png');
`;
export const HeaderWrapper = styled.div`
  width: 80vw;
  height: 32px;
  padding-block: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-bottom: ${(props) => (props.page !== 3 ? '1px solid #d6d6d6' : '')};
  color: ${(props) =>
    props.page === 0 || props.page === 5 ? 'white' : 'black'};
`;
// 헤더 컨테이너
export const FirstHeader = styled.header`
  height: 72px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background: ${(props) => (props.page === 3 ? 'white' : 'transparent')};
`;

// 헤더 왼쪽 컨테이너
export const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

// 헤더 오른쪽 컨테이너
export const HeaderRight = styled.div`
  display: flex;
  gap: 30px;
  font-size: 19px;
`;

// 메인 h1, p 컨테이너
export const MainContainer = styled.div`
  position: relative;
  top: 58%;
  left: 10%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  h1 {
    font-size: 3.125rem;
  }

  p {
    font-size: 1.875rem;
  }
`;

// 화살표 컨테이너
export const Bottom = styled.div`
  position: relative;
  top: 58%;
  display: flex;
  justify-content: center;
`;

export const NavButton = styled.button`
  font-size: 16px;
  color: ${(props) =>
    props.page === 0 || props.page === 5 ? 'white' : 'black'};
  cursor: pointer;
  font-weight: ${(props) => (props.page == props.selectPage ? 700 : 400)};
`;
