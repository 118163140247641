import styled from 'styled-components';

export const SecondContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Top = styled.div`
  height: 25%;
  display: flex;
  justify-content: center;

  > div {
    position: relative;
    top: 40%;
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 0px;
  }
  p {
    border-bottom: 1px solid white;
    font-size: 25px;
  }
`;

export const Bottom = styled.div`
  position: relative;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  width: 80%;
  height: 380px;
  background: white;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
`;

export const BottomLeft = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  span {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    letter-spacing: -0.329px;
  }
`;
export const Introduction = styled.span`
  margin-bottom: 48px;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.329px;
`;
export const BottomRight = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextBox = styled.div`
  width: max-content;
  height: 300px;
  display: flex;
  position: relative;
  flex-direction: column;
  h1 {
    position: relative;
    left: -32px;
    color: #000000;
    font-size: 3.75rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
  }

  p {
    color: #7c7c7c;
    font-size: 32px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
`;
export const SecondSectionContent = styled.div`
  color: #2c2c2c;
  margin-top: 100px;
  span {
    color: #2c2c2c;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.329px;
  }
  #first {
    position: relative;
    left: -10px;
  }
  #second {
  }
`;
export const ServiceButton = styled.div`
  background: #6eb3b5;
  width: fit-content;
  font-weight: 500;
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px 24px;
  margin-top: 32px;
  span {
    line-height: 100%;
    letter-spacing: -0.483px;
    font-size: 20px;
    color: #ffffff;
  }
`;
export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  gap: 24px;
  padding-top: 62px;
`;
