import styled from 'styled-components';
import { useEffect, useState } from 'react';

const StyledSection = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.7s ease-in-out;
  transform: translateY(-${(props) => props.currentPage * props.height}px);
`;

const MobilePageSection = ({ children, currentPage, ...props }) => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledSection height={height} currentPage={currentPage} {...props}>
      {children}
    </StyledSection>
  );
};

export default MobilePageSection;
