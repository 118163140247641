import React from "react";
import styled, { keyframes } from 'styled-components';

const BottomArrow = () => {
    return (
        <AnimatedBottomArrow width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 33.6L44.2667 21.3333L48 25.0666L32 41.0666L16 25.0666L19.7333 21.3333L32 33.6Z" fill="white" />
        </AnimatedBottomArrow>
    );
};

export default BottomArrow;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const AnimatedBottomArrow = styled.svg`
  animation: ${float} 3s ease-in-out infinite;
`;