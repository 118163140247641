import React, { useState, useEffect, useCallback, useRef } from 'react';
import FullPageWrapper from './FullPageWrapper';
import FullPageSection from './FullPageSection';
import {
  FirstHeader,
  HeaderLeft,
  HeaderRight,
  NavButton,
  HeaderWrapper,
} from '../../page/first/First.styled';
import MainLogo from '../../images/Mainlogo';
import FontLogo from '../../images/FontLogo';
import BrownFontLogo from '../../images/BrownFontLogo';
import BrownMainLogo from '../../images/BrownMainLogo';

const navArray = [
  { label: '회사 소개', value: 0 },
  { label: '서비스 소개', value: 1 },
  { label: '내부 분위기', value: 2 },
  { label: '인력 채용', value: 3 },
];

const DesktopFullPage = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const isTransitioning = useRef(false);
  const lastInteractionTime = useRef(0);
  const touchStartY = useRef(null);
  const lastTimeRef = useRef(0);
  const TRANSITION_DURATION = 700; // ms
  const INTERACTION_COOLDOWN = 1000; // ms

  const changePage = useCallback(
    (direction) => {
      const now = Date.now();
      if (
        isTransitioning.current ||
        now - lastInteractionTime.current < INTERACTION_COOLDOWN
      ) {
        return;
      }
      const newPage = currentPage + direction;
      console.log('newPage', newPage);
      console.log('children count', React.Children.count(children));
      if (newPage >= 0 && newPage < React.Children.count(children)) {
        isTransitioning.current = true;
        lastInteractionTime.current = now;

        setTimeout(() => {
          setCurrentPage(newPage);
          isTransitioning.current = false;
        }, TRANSITION_DURATION);
      }
    },
    [currentPage, children, setCurrentPage],
  );
  // useEffect(() => {
  //     console.log('current Page' , currentPage)
  // }, [currentPage]);
  const handleWheel = useCallback(
    (event) => {
      const now = Date.now();
      if (now - lastTimeRef.current < 100) {
        return; // 500ms 이내에 호출된 이벤트는 무시
      }

      lastTimeRef.current = now;
      event.preventDefault();

      console.log(event.deltaY);
      if (Math.abs(event.deltaY) > 100) {
        changePage(1);
      } else if (Math.abs(event.deltaY) < -100) {
        changePage(-1);
      }
    },
    [changePage],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowDown' || event.key === 'PageDown') {
        event.preventDefault();
        changePage(1);
      } else if (event.key === 'ArrowUp' || event.key === 'PageUp') {
        event.preventDefault();
        changePage(-1);
      }
    },
    [changePage],
  );

  const handleTouchStart = useCallback((event) => {
    touchStartY.current = event.touches[0].clientY;
  }, []);

  const handleTouchEnd = useCallback(
    (event) => {
      if (touchStartY.current === null) return;

      const touchEndY = event.changedTouches[0].clientY;
      const diff = touchStartY.current - touchEndY;

      if (Math.abs(diff) > 50) {
        // 50px 이상의 스와이프만 감지
        if (diff > 0) {
          changePage(1); // 위로 스와이프: 다음 페이지
        } else {
          changePage(-1); // 아래로 스와이프: 이전 페이지
        }
      }

      touchStartY.current = null;
    },
    [changePage],
  );

  // 터치패드 스와이프 이벤트 처리
  const handleTouchPadSwipe = useCallback(
    (event) => {
      event.preventDefault();
      if (Math.abs(event.deltaY) > 50) {
        // 50px 이상의 스와이프만 감지
        if (event.deltaY > 0) {
          changePage(1);
        } else {
          changePage(-1);
        }
      }
    },
    [changePage],
  );

  useEffect(() => {
    const wheelHandler = (e) => {
      e.preventDefault();
      handleWheel(e);
    };

    window.addEventListener('wheel', wheelHandler, { passive: false });
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouchStart, { passive: false });
    window.addEventListener('touchend', handleTouchEnd, { passive: false });
    // 터치패드 스와이프를 위한 이벤트 리스너 추가
    window.addEventListener('wheel', handleTouchPadSwipe, { passive: false });

    return () => {
      window.removeEventListener('wheel', wheelHandler);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('wheel', handleTouchPadSwipe);
    };
  }, [
    handleWheel,
    handleKeyDown,
    handleTouchStart,
    handleTouchEnd,
    handleTouchPadSwipe,
  ]);

  return (
    <FullPageWrapper>
      <FirstHeader page={currentPage}>
        <HeaderWrapper page={currentPage}>
          <HeaderLeft>
            {currentPage === 0 || currentPage === 5 ? (
              <>
                <MainLogo />
                <FontLogo />
              </>
            ) : (
              <>
                <BrownMainLogo />
                <BrownFontLogo />
              </>
            )}
          </HeaderLeft>
          <HeaderRight>
            {navArray.map((item, index) => (
              <NavButton
                page={currentPage}
                key={item.label}
                onClick={() => {
                  setCurrentPage(index);
                }}
                selectPage={index}
              >
                {item.label}
              </NavButton>
            ))}
          </HeaderRight>{' '}
        </HeaderWrapper>
      </FirstHeader>
      {React.Children.map(children, (child, index) => (
        <FullPageSection key={index} currentPage={currentPage}>
          {child}
        </FullPageSection>
      ))}
    </FullPageWrapper>
  );
};

export default DesktopFullPage;
