import React from 'react';

const MobileFooterLogo = () => {
  return (
    <svg
      width="175"
      height="84"
      viewBox="0 0 175 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.6719 52.525C61.6719 52.525 67.0165 12.7658 77.695 0.928427C78.7527 -0.243247 80.0635 -0.38803 80.8735 0.968644C83.2074 4.87244 86.0849 13.7498 87.2731 20.7718C87.2731 20.7718 83.1435 7.08708 80.2048 3.59351C79.6373 2.91786 78.9685 2.89373 78.3024 3.47286C73.965 7.23723 66.7607 26.6892 61.6745 52.525H61.6719Z"
        fill="white"
      />
      <path
        d="M61.5879 52.7959C61.5879 52.7959 90.9858 3.77332 107.06 7.85675C109.319 8.43052 110.643 10.4923 110.792 12.8303C110.963 15.4498 109.814 19.5628 103.876 24.9037C103.876 24.9037 111.794 14.6643 107.115 11.3101C101.059 6.96928 82.9582 23.0027 61.5879 52.7986V52.7959Z"
        fill="white"
      />
      <path
        d="M61.9434 52.402C61.9434 52.402 97.5864 19.8687 110.46 25.8155C112.714 26.8558 113.956 29.4136 113.173 31.7811C112.445 33.9743 110.266 36.8888 104.396 39.6504C104.396 39.6504 113.668 33.06 110.668 29.0731C106.786 23.9146 86.764 32.7758 61.9434 52.402Z"
        fill="white"
      />
      <path
        d="M62.1081 52.4911C62.1081 52.4911 95.9927 32.7496 105.041 39.6992C106.621 40.9138 107.092 43.2169 106.04 44.9141C105.057 46.504 102.819 48.3862 97.8018 49.5579C97.8018 49.5579 106.261 45.9142 104.59 42.2678C102.43 37.5489 84.744 42.1632 62.1055 52.4911H62.1081Z"
        fill="white"
      />
      <path
        d="M12.3314 83.7343H11.1218L9.56855 79.6723H3.31544L1.74617 83.7343H0.53125L5.81456 69.9745H7.10408L12.3314 83.7343ZM9.16891 78.6427L6.45932 71.4974L3.69377 78.6427H9.16891Z"
        fill="white"
      />
      <path
        d="M22.3235 82.1492C22.923 82.6211 23.589 82.8624 24.3217 82.8758C25.2809 82.8865 26.0588 82.6479 26.6583 82.1626C27.2578 81.6719 27.5588 81.015 27.5588 80.1892C27.5588 79.645 27.4176 79.1865 27.1299 78.8165C26.8448 78.4491 26.4931 78.1569 26.0695 77.9424C25.6459 77.7279 25.0704 77.4786 24.3377 77.1997C23.4931 76.8807 22.8137 76.5857 22.3022 76.3123C21.7933 76.0388 21.3617 75.6473 21.0073 75.1406C20.6503 74.6312 20.4771 73.9823 20.4771 73.1967C20.4771 72.5077 20.6556 71.9071 21.0153 71.395C21.375 70.8802 21.8679 70.4861 22.494 70.2153C23.1201 69.9418 23.8155 69.8104 24.5855 69.8238C25.1903 69.8345 25.7498 69.9525 26.264 70.1724C26.7729 70.3976 27.2205 70.7193 27.5988 71.1456C27.9745 71.5719 28.2596 72.0814 28.4514 72.6793L27.4096 73.0412C27.2205 72.3951 26.8661 71.8669 26.3413 71.462C25.8164 71.0545 25.2249 70.8427 24.5722 70.8319C23.7116 70.8319 23.0056 71.0464 22.4487 71.4781C21.8945 71.9124 21.6175 72.4889 21.6175 73.2128C21.6175 73.7839 21.7613 74.2612 22.0517 74.6446C22.3421 75.0226 22.7072 75.3256 23.1414 75.5481C23.5757 75.7707 24.1619 76.0227 24.8945 76.3015C25.7151 76.6072 26.3786 76.8967 26.8821 77.1702C27.3857 77.4437 27.8173 77.8244 28.1716 78.3124C28.526 78.8031 28.7018 79.4358 28.7018 80.2107C28.7018 80.9346 28.518 81.5781 28.1503 82.1438C27.7853 82.7095 27.2684 83.1439 26.605 83.4495C25.9443 83.7552 25.177 83.9027 24.3057 83.8866C23.3839 83.8758 22.55 83.6077 21.8066 83.0876C21.0633 82.5647 20.4185 81.8274 19.875 80.8756L20.8608 80.3421C21.2391 81.0794 21.7294 81.6826 22.3288 82.1519L22.3235 82.1492Z"
        fill="white"
      />
      <path
        d="M39.5256 69.9745V83.7343H38.3906V69.9745H39.5256Z"
        fill="white"
      />
      <path
        d="M60.985 83.7343H59.7754L58.2221 79.6723H51.9717L50.4024 83.7343H49.1875L54.4708 69.9745H55.7603L60.9877 83.7343H60.985ZM57.8252 78.6427L55.1156 71.4974L52.35 78.6427H57.8252Z"
        fill="white"
      />
      <path
        d="M80.2474 83.7343H79.1124L71.3486 72.0122V83.7343H70.2109V69.9745H71.3486L79.1124 81.6564V69.9745H80.2474V83.7343Z"
        fill="white"
      />
      <path
        d="M99.8188 70.7173C100.837 71.3152 101.625 72.1464 102.195 73.2135C102.763 74.2806 103.048 75.4951 103.048 76.8545C103.048 78.2139 102.763 79.4257 102.195 80.4955C101.628 81.5626 100.837 82.3938 99.8188 82.9917C98.801 83.5896 97.65 83.8872 96.3632 83.8872C95.0763 83.8872 93.92 83.5896 92.9076 82.9917C91.8898 82.3938 91.0959 81.5626 90.5284 80.4955C89.9609 79.4257 89.6758 78.2139 89.6758 76.8545C89.6758 75.4951 89.9609 74.2806 90.5284 73.2135C91.0959 72.1464 91.8898 71.3152 92.9076 70.7173C93.9227 70.1194 95.0737 69.8218 96.3632 69.8218C97.6527 69.8218 98.8037 70.1194 99.8188 70.7173ZM99.2326 82.1042C100.072 81.5921 100.725 80.8736 101.194 79.962C101.66 79.0477 101.894 78.0128 101.894 76.8545C101.894 75.6962 101.66 74.6613 101.194 73.747C100.725 72.8327 100.072 72.1169 99.2326 71.6048C98.3934 71.09 97.4369 70.8326 96.3658 70.8326C95.2948 70.8326 94.3356 71.09 93.4937 71.6048C92.6545 72.1169 92.0017 72.8327 91.5355 73.747C91.0665 74.6613 90.8348 75.6962 90.8348 76.8545C90.8348 78.0128 91.0665 79.0477 91.5355 79.962C92.0017 80.8736 92.6545 81.5921 93.4937 82.1042C94.333 82.619 95.2895 82.8764 96.3658 82.8764C97.4422 82.8764 98.3934 82.619 99.2326 82.1042Z"
        fill="white"
      />
      <path
        d="M117.083 83.7343H115.874L110.684 69.9745H111.899L116.462 82.0961L120.819 69.9745H122.028L117.086 83.7343H117.083Z"
        fill="white"
      />
      <path
        d="M140.114 83.7343H138.899L137.346 79.6723H131.098L129.526 83.7343H128.316L133.6 69.9745H134.887L140.117 83.7343H140.114ZM136.951 78.6427L134.242 71.4974L131.479 78.6427H136.951Z"
        fill="white"
      />
      <path
        d="M156.949 70.9826H152.764V83.7343H151.629V70.9826H147.48V69.9745H156.949V70.9826Z"
        fill="white"
      />
      <path
        d="M174.468 70.9826H167.272V76.1278H173.503V77.16H167.272V82.7262H174.468V83.7343H166.137V69.9745H174.468V70.9826Z"
        fill="white"
      />
    </svg>
  );
};

export default MobileFooterLogo;
