import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html,
body {
  color: white;
  overflow: hidden;
  height: 100%;
  padding: 0;
  margin: 0;
    
    * {
        font-family: 'wanted Sans Variable';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } }

  code {
        font-family: 'wanted Sans Variable';
  }



  div,p,span,h1,button,h2,h3,h4{
      font-size: 16px;  
      padding: 0;
      margin: 0;
  }
  button{
        border: none;
        outline: none;
        background-color: transparent;
    }
  #root {
    height: 100%;
  }
`;

export default GlobalStyle;
