import React from 'react';

const MainLogo = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0527344 31.0162C0.0527344 31.0162 3.16421 8.18652 9.37821 1.38997C9.99514 0.71564 10.7551 0.635785 11.229 1.41659C12.588 3.6614 14.26 8.75437 14.9574 12.7915C14.9574 12.7915 12.5523 4.93021 10.8445 2.92496C10.5137 2.53456 10.1203 2.52569 9.73585 2.85398C7.20554 5.01006 3.01221 16.1809 0.0527344 31.0162Z"
        fill="white"
      />
      <path
        d="M0 31.1759C0 31.1759 17.122 3.02261 26.4833 5.36503C27.7976 5.69332 28.5665 6.88227 28.6559 8.22206C28.7543 9.73043 28.0837 12.0906 24.6236 15.1517C24.6236 15.1517 29.2371 9.26905 26.5101 7.34366C22.9873 4.85041 12.4459 14.0603 0 31.1671L0 31.1759Z"
        fill="white"
      />
      <path
        d="M0.207031 30.9538C0.207031 30.9538 20.9681 12.2677 28.4606 15.6838C29.775 16.2782 30.4992 17.7511 30.0432 19.1087C29.623 20.3686 28.3533 22.0455 24.9289 23.6249C24.9289 23.6249 30.3293 19.8362 28.5858 17.547C26.3237 14.5835 14.6646 19.6765 0.207031 30.9449V30.9538Z"
        fill="white"
      />
      <path
        d="M0.303863 30.9983C0.303863 30.9983 20.0367 19.6589 25.3029 23.6516C26.2239 24.3526 26.501 25.6746 25.8841 26.6506C25.3119 27.5645 24.0065 28.647 21.0828 29.3213C21.0828 29.3213 26.0093 27.2274 25.0347 25.1334C23.774 22.4272 13.474 25.0713 0.294922 31.0072L0.303863 30.9983Z"
        fill="white"
      />
    </svg>
  );
};

export default MainLogo;
