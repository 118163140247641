import React from 'react';

const BrownFontLogo = () => {
  return (
    <svg
      width="169"
      height="14"
      viewBox="0 0 169 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9485 13.1672H10.7781L9.27568 9.37745H3.22248L1.70263 13.1672H0.523438L5.64201 0.314331H6.89109L11.9573 13.1672H11.9485ZM8.89135 8.41094L6.27091 1.73866L3.58934 8.41094H8.89135Z"
        fill="#4A3411"
      />
      <path
        d="M21.6268 11.6831C22.2033 12.124 22.8496 12.3529 23.5659 12.3614C24.4918 12.3699 25.2517 12.1494 25.8282 11.6916C26.4047 11.2338 26.7017 10.6149 26.7017 9.85185C26.7017 9.34317 26.5619 8.91078 26.2824 8.57166C26.0116 8.23253 25.6622 7.95275 25.2517 7.75776C24.8412 7.56276 24.2821 7.32537 23.5746 7.06255C22.7536 6.76581 22.0985 6.48604 21.6006 6.23169C21.1027 5.97735 20.6922 5.61279 20.3428 5.13801C19.9934 4.66324 19.8274 4.05282 19.8274 3.3237C19.8274 2.67936 20.0021 2.1198 20.3515 1.63655C20.7009 1.1533 21.1726 0.788738 21.784 0.534394C22.3867 0.28005 23.0593 0.152878 23.8105 0.169835C24.3957 0.178313 24.9373 0.288528 25.4351 0.500481C25.933 0.712434 26.361 1.00917 26.7279 1.40764C27.0947 1.80611 27.3655 2.28089 27.549 2.84044L26.5357 3.17957C26.3523 2.57762 26.0116 2.08589 25.4963 1.70437C24.9897 1.32286 24.4132 1.12786 23.7843 1.11938C22.9545 1.11938 22.2644 1.32286 21.7316 1.72133C21.1988 2.12828 20.928 2.6624 20.928 3.34065C20.928 3.87477 21.0678 4.32411 21.3473 4.6802C21.6268 5.03628 21.9849 5.31606 22.4042 5.52801C22.8234 5.73996 23.3912 5.96887 24.0987 6.23169C24.8936 6.51995 25.5312 6.79125 26.0204 7.04559C26.5095 7.29994 26.9288 7.65602 27.2694 8.11384C27.6101 8.57166 27.7848 9.16512 27.7848 9.88576C27.7848 10.564 27.6101 11.166 27.252 11.6916C26.9026 12.2172 26.396 12.6242 25.7583 12.9125C25.1207 13.2007 24.3782 13.3364 23.531 13.3194C22.64 13.3109 21.8277 13.0566 21.1114 12.5733C20.3864 12.0816 19.7663 11.3949 19.2422 10.5047L20.1943 10.0045C20.5611 10.6912 21.0328 11.2592 21.618 11.6916L21.6268 11.6831Z"
        fill="#4A3411"
      />
      <path
        d="M38.2939 0.305374V13.1582H37.1934V0.305374H38.2939Z"
        fill="#4A3411"
      />
      <path
        d="M59.0813 13.1672H57.9109L56.4085 9.37745H50.3553L48.8354 13.1672H47.6562L52.7748 0.314331H54.0239L59.0901 13.1672H59.0813ZM56.0154 8.41094L53.395 1.73866L50.7134 8.41094H56.0154Z"
        fill="#4A3411"
      />
      <path
        d="M77.7394 13.1672H76.6388L69.1182 2.21343V13.1672H68.0176V0.314331H69.1182L76.6388 11.2257V0.314331H77.7394V13.1672Z"
        fill="#4A3411"
      />
      <path
        d="M96.6929 1.00096C97.6799 1.56051 98.4398 2.33202 98.9901 3.33244C99.5404 4.33286 99.8112 5.46893 99.8112 6.73217C99.8112 7.99541 99.5404 9.13148 98.9901 10.1319C98.4398 11.1323 97.6712 11.9038 96.6929 12.4634C95.7146 13.0229 94.5878 13.3027 93.3475 13.3027C92.1071 13.3027 90.9803 13.0229 90.002 12.4634C89.015 11.9038 88.2464 11.1323 87.6961 10.1319C87.1458 9.13148 86.875 7.99541 86.875 6.73217C86.875 5.46893 87.1458 4.32438 87.6961 3.33244C88.2464 2.3405 89.015 1.56051 90.002 1.00096C90.9803 0.441399 92.0984 0.161621 93.3475 0.161621C94.5965 0.161621 95.7059 0.441399 96.6929 1.00096ZM96.1251 11.641C96.9375 11.1578 97.5664 10.488 98.0206 9.64017C98.4748 8.78387 98.7019 7.81737 98.7019 6.74065C98.7019 5.66392 98.4748 4.68894 98.0206 3.84113C97.5664 2.98484 96.9375 2.31507 96.1251 1.84029C95.3128 1.35704 94.3869 1.11965 93.3475 1.11965C92.308 1.11965 91.3822 1.35704 90.5698 1.84029C89.7575 2.32354 89.1286 2.98484 88.6744 3.84113C88.2202 4.69742 87.9931 5.66392 87.9931 6.74065C87.9931 7.81737 88.2202 8.79235 88.6744 9.64017C89.1286 10.4965 89.7575 11.1662 90.5698 11.641C91.3822 12.1243 92.308 12.3616 93.3475 12.3616C94.3869 12.3616 95.3128 12.1243 96.1251 11.641Z"
        fill="#4A3411"
      />
      <path
        d="M113.42 13.1672H112.25L107.219 0.314331H108.398L112.818 11.6411L117.037 0.314331H118.207L113.42 13.1672Z"
        fill="#4A3411"
      />
      <path
        d="M135.721 13.1672H134.542L133.039 9.37745H126.986L125.466 13.1672H124.296L129.414 0.314331H130.664L135.73 13.1672H135.721ZM132.664 8.41094L130.043 1.73866L127.371 8.41094H132.673H132.664Z"
        fill="#4A3411"
      />
      <path
        d="M152.027 1.2554H147.974V13.1672H146.873V1.2554H142.855V0.314331H152.027V1.2554Z"
        fill="#4A3411"
      />
      <path
        d="M169 1.2554H162.029V6.0625H168.065V7.02901H162.029V12.2261H169V13.1672H160.929V0.314331H169V1.2554Z"
        fill="#4A3411"
      />
    </svg>
  );
};

export default BrownFontLogo;
