import React from 'react';
import BottomArrow from '../../images/BottomArrow';
import FontLogo from '../../images/FontLogo';
import MainLogo from '../../images/Mainlogo';
import {
  Bottom,
  FirstContainer,
  FirstHeader,
  HeaderLeft,
  HeaderRight,
  MainContainer,
} from './First.styled';

const First = () => {
  return (
    <FirstContainer>
      <MainContainer>
        <h1>
          세상의 모든 <br /> 구조를 다시 바라봅니다.
        </h1>
        <p>
          시장의 연결성을 찾아 구상하고 세상을 변화시키는 '아시아노베이트'
          입니다.
        </p>
      </MainContainer>
    </FirstContainer>
  );
};

export default First;
