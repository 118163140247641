import React from 'react';
import styled from 'styled-components';
import GraphIcon from '../../images/GraphIcon';
import ElectricBulb from '../../images/ElectricBulb';
import MobileElectricBulb from '../../images/MobileElectricBulb';
import MobileGraphIcon from '../../images/MobileGraphIcon';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const MobileFourth = () => {
  return (
    <FourthContainer>
      <Content url={`${cdnUrl}/asia_bg.png`} color={'#ffffff'}>
        <MobileElectricBulb />
        <h1>Team Mission</h1>
        <p>
          새상을 새로운 관점으로 바라봄으로써
          <br />
          기존의 번거로운 구조와 서비스를
          <br />
          새롭게 개선하여 개발합니다.
        </p>
      </Content>
      <Content url={`${cdnUrl}/asia_bg_right.png`} color={'#4A3411'}>
        <MobileGraphIcon />
        <h1>Team Vision</h1>
        <p>
          당사는 최신 기술과 창의적인 아이디어를 <br />
          바탕으로, 고객 맞춤형 솔루션을 제공하며,
          <br />
          다양한 산업 분야에서 경쟁력을
          <br />
          강화할 수 있도록 돕고 있습니다.
        </p>
      </Content>
    </FourthContainer>
  );
};

export default MobileFourth;

export const FourthContainer = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); // 하얀색 반투명 레이어
  }
`;

const Content = styled.div`
  width: 100%;
  height: 50dvh;
  background-image: ${(props) => `url(${props.url})`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  color: ${(props) => props.color};
  gap: 12px;
  h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  p {
    color: ${(props) => props.color};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8;
  }
`;
