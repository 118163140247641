import React from 'react';
import Desktop from './components/Screen/Desktop';
import GlobalStyle from './styles/GlobalStyle';
import { useMobile } from './utils/useMobile';
import Mobile from './components/Screen/Mobile';

const App = () => {
  const { isMobile } = useMobile();

  return (
    <>
      <GlobalStyle />
      {!isMobile ? <Desktop /> : <Mobile />}
    </>
  );
};

export default App;
