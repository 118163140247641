import React from 'react';

const AsianoBateFooterLogo = () => {
  return (
    <svg
      width="327"
      height="158"
      viewBox="0 0 327 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.945 98.7469C114.945 98.7469 124.993 23.9997 145.069 1.74544C147.057 -0.457304 149.522 -0.729497 151.044 1.82105C155.432 9.16018 160.842 25.8496 163.076 39.051C163.076 39.051 155.312 13.3237 149.787 6.75581C148.72 5.48557 147.463 5.44021 146.211 6.52898C138.056 13.606 124.512 50.1756 114.95 98.7469H114.945Z"
        fill="white"
      />
      <path
        d="M114.789 99.2563C114.789 99.2563 170.057 7.09384 200.276 14.7707C204.523 15.8494 207.013 19.7256 207.293 24.121C207.614 29.0457 205.455 36.778 194.29 46.8189C194.29 46.8189 209.176 27.5688 200.381 21.263C188.996 13.1022 154.965 43.2451 114.789 99.2614V99.2563Z"
        fill="white"
      />
      <path
        d="M115.459 98.5158C115.459 98.5158 182.468 37.3531 206.671 48.5331C210.908 50.4889 213.242 55.2976 211.77 59.7485C210.402 63.8717 206.305 69.3509 195.271 74.5427C195.271 74.5427 212.701 62.1529 207.061 54.6575C199.764 44.9593 162.122 61.6186 115.459 98.5158Z"
        fill="white"
      />
      <path
        d="M115.767 98.6833C115.767 98.6833 179.47 61.5693 196.48 74.6345C199.45 76.9179 200.337 81.2478 198.358 84.4385C196.51 87.4276 192.302 90.9661 182.871 93.1689C182.871 93.1689 198.774 86.3187 195.633 79.4635C191.571 70.592 158.322 79.2669 115.762 98.6833H115.767Z"
        fill="white"
      />
      <path
        d="M22.1843 157.417H19.9103L16.9901 149.781H5.23428L2.28405 157.417H0L9.93262 131.549H12.3569L22.1843 157.417ZM16.2388 147.845L11.1448 134.412L5.94555 147.845H16.2388Z"
        fill="white"
      />
      <path
        d="M40.9684 154.437C42.0954 155.324 43.3476 155.778 44.7251 155.803C46.5283 155.823 47.9909 155.375 49.1179 154.462C50.2449 153.54 50.8109 152.305 50.8109 150.752C50.8109 149.729 50.5454 148.867 50.0044 148.172C49.4685 147.481 48.8073 146.932 48.0109 146.528C47.2145 146.125 46.1326 145.656 44.7551 145.132C43.1673 144.532 41.89 143.978 40.9283 143.464C39.9716 142.95 39.1602 142.214 38.494 141.261C37.8228 140.303 37.4972 139.083 37.4972 137.607C37.4972 136.311 37.8328 135.182 38.509 134.219C39.1852 133.251 40.1119 132.51 41.289 132.001C42.4661 131.487 43.7734 131.24 45.2209 131.265C46.358 131.286 47.4098 131.507 48.3765 131.921C49.3332 132.344 50.1747 132.949 50.886 133.75C51.5922 134.552 52.1282 135.51 52.4888 136.634L50.5304 137.314C50.1747 136.099 49.5085 135.106 48.5218 134.345C47.535 133.579 46.4231 133.181 45.1959 133.161C43.578 133.161 42.2507 133.564 41.2038 134.375C40.162 135.192 39.641 136.276 39.641 137.637C39.641 138.71 39.9115 139.608 40.4575 140.328C41.0035 141.039 41.6897 141.609 42.5061 142.027C43.3226 142.445 44.4245 142.919 45.802 143.444C47.3447 144.018 48.5919 144.563 49.5386 145.077C50.4853 145.591 51.2967 146.307 51.9629 147.224C52.6291 148.146 52.9597 149.336 52.9597 150.793C52.9597 152.154 52.6141 153.363 51.9228 154.427C51.2366 155.491 50.2649 156.307 49.0177 156.882C47.7755 157.456 46.3329 157.734 44.695 157.703C42.9619 157.683 41.3942 157.179 39.9967 156.201C38.5992 155.218 37.387 153.832 36.3652 152.043L38.2185 151.04C38.9298 152.426 39.8514 153.56 40.9784 154.442L40.9684 154.437Z"
        fill="white"
      />
      <path
        d="M73.3096 131.549V157.417H71.1758V131.549H73.3096Z"
        fill="white"
      />
      <path
        d="M113.652 157.417H111.378L108.458 149.781H96.7069L93.7567 157.417H91.4727L101.405 131.549H103.83L113.657 157.417H113.652ZM107.711 147.845L102.617 134.412L97.4182 147.845H107.711Z"
        fill="white"
      />
      <path
        d="M149.865 157.417H147.731L133.135 135.38V157.417H130.996V131.549H133.135L147.731 153.511V131.549H149.865V157.417Z"
        fill="white"
      />
      <path
        d="M186.661 132.945C188.574 134.069 190.057 135.632 191.129 137.638C192.195 139.644 192.731 141.928 192.731 144.483C192.731 147.039 192.195 149.317 191.129 151.328C190.062 153.335 188.574 154.897 186.661 156.021C184.747 157.145 182.583 157.705 180.164 157.705C177.745 157.705 175.571 157.145 173.668 156.021C171.754 154.897 170.262 153.335 169.195 151.328C168.128 149.317 167.592 147.039 167.592 144.483C167.592 141.928 168.128 139.644 169.195 137.638C170.262 135.632 171.754 134.069 173.668 132.945C175.576 131.821 177.74 131.262 180.164 131.262C182.588 131.262 184.752 131.821 186.661 132.945ZM185.559 154.353C187.136 153.39 188.364 152.039 189.245 150.325C190.122 148.606 190.563 146.661 190.563 144.483C190.563 142.306 190.122 140.36 189.245 138.641C188.364 136.922 187.136 135.576 185.559 134.614C183.981 133.646 182.183 133.162 180.169 133.162C178.156 133.162 176.352 133.646 174.77 134.614C173.192 135.576 171.965 136.922 171.088 138.641C170.206 140.36 169.771 142.306 169.771 144.483C169.771 146.661 170.206 148.606 171.088 150.325C171.965 152.039 173.192 153.39 174.77 154.353C176.347 155.321 178.146 155.804 180.169 155.804C182.193 155.804 183.981 155.321 185.559 154.353Z"
        fill="white"
      />
      <path
        d="M219.117 157.417H216.843L207.086 131.549H209.37L217.95 154.337L226.14 131.549H228.414L219.122 157.417H219.117Z"
        fill="white"
      />
      <path
        d="M262.416 157.417H260.132L257.211 149.781H245.466L242.51 157.417H240.236L250.169 131.549H252.588L262.421 157.417H262.416ZM256.47 147.845L251.376 134.412L246.182 147.845H256.47Z"
        fill="white"
      />
      <path
        d="M294.065 133.444H286.196V157.417H284.063V133.444H276.264V131.549H294.065V133.444Z"
        fill="white"
      />
      <path
        d="M327.001 133.444H313.472V143.117H325.187V145.058H313.472V155.522H327.001V157.417H311.338V131.549H327.001V133.444Z"
        fill="white"
      />
    </svg>
  );
};

export default AsianoBateFooterLogo;
