import React from 'react';
import {
  SecondContainer,
  Top,
  Bottom,
  BottomContainer,
  BottomLeft,
  BottomRight,
  ServiceButton,
  LogoBox,
  TextBox,
  Introduction,
  SecondSectionContent,
} from './Second.styled';
import EatDaLogo from '../../images/EatDaLogo';
import EatDaFont from '../../images/EatDaFont';
import ArrowInBtn from '../../images/ArrowInBtn';

const Second = () => {
  const handleClick = () => {
    window.open('https://eat-da.com', '_blank');
  };

  return (
    <SecondContainer>
      <BottomContainer>
        <BottomLeft>
          <Introduction>서비스 소개</Introduction>
          <TextBox>
            <h1 id={'logo'}>“식자재를 잇다”</h1>
            <p>장사의 모든 시스템을 한 화면에 담다</p>
          </TextBox>
          <ServiceButton as={'button'} onClick={handleClick}>
            <span>바로 가기</span>
            <ArrowInBtn />
          </ServiceButton>

          <SecondSectionContent>
            <span id={'first'}>
              "식자재를 잇다"에서 견적 · 주문 그리고 시스템까지
            </span>
            <br />
            <span id={'second'}>한 번에 모두 무료로 이용해 보세요</span>
          </SecondSectionContent>
        </BottomLeft>
        <BottomRight>
          <LogoBox>
            <EatDaLogo />
            <EatDaFont />
          </LogoBox>
        </BottomRight>
      </BottomContainer>
    </SecondContainer>
  );
};

export default Second;
