import React from 'react';

const MobileEatDaLogo = () => {
  return (
    <svg
      width="169"
      height="89"
      viewBox="0 0 169 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5050_13255)">
        <path
          d="M53.5352 26.8976C53.5564 26.8638 53.5733 26.8342 53.5945 26.8003L62.5164 13.2166H32.4298C27.1207 13.2166 22.8184 17.5189 22.8184 22.828C22.8184 28.1371 27.1207 32.4394 32.4298 32.4394H49.9013L53.5352 26.8976Z"
          fill="url(#paint0_linear_5050_13255)"
        />
        <path
          d="M136.412 13.2166H117.393C117.253 15.1668 116.631 17.1043 115.48 18.8557L106.559 32.4394H136.412C141.722 32.4394 146.024 28.1371 146.024 22.828C146.024 17.5189 141.722 13.2166 136.412 13.2166Z"
          fill="url(#paint1_linear_5050_13255)"
        />
        <path
          d="M83.7477 2.4586C78.2821 -1.133 70.9424 0.389941 67.3508 5.85561L62.5154 13.2207L53.5935 26.8045C53.5724 26.8383 53.5555 26.868 53.5343 26.9018L49.9004 32.4436C49.9004 32.4436 52.3117 39.6268 55.5649 42.1058C56.0048 42.4993 56.4786 42.8673 56.9905 43.2015C62.4562 46.7931 69.7959 45.2702 73.3875 39.8045L78.2017 32.4732L78.1932 32.4521L78.2017 32.4394L81.5903 27.3164L81.8991 26.8468C81.8991 26.8468 81.916 26.8172 81.9244 26.8045L87.1701 18.8175C88.2997 17.0788 88.9173 15.154 89.0569 13.2207C89.3572 9.07916 87.4663 4.90377 83.7477 2.46283V2.4586Z"
          fill="url(#paint2_linear_5050_13255)"
        />
        <path
          d="M112.084 2.4586C106.618 -1.133 99.2744 0.389941 95.687 5.85561L90.8517 13.2207L87.1755 18.8175C87.1755 18.8175 87.1585 18.8471 87.1501 18.8598L81.9044 26.8468C81.8705 26.9018 81.8367 26.9526 81.8029 27.0076L81.5956 27.3164L78.2282 32.4394L78.207 32.4732C78.3466 32.8497 80.4661 38.4338 83.017 41.2174C83.6854 41.9578 84.4553 42.6262 85.3268 43.1973C90.7924 46.7889 98.1364 45.2659 101.724 39.8003L106.559 32.4352L115.481 18.8514C116.632 17.1042 117.254 15.1625 117.393 13.2123C117.694 9.0707 115.803 4.89531 112.084 2.45437V2.4586Z"
          fill="url(#paint3_linear_5050_13255)"
        />
        <path
          d="M16.3539 73.5165L14.5898 76.3466C11.6751 75.4371 9.67409 73.6138 8.53611 71.2744C7.37276 73.8084 5.31256 75.8263 2.28783 76.795L0.494141 73.9099C4.79645 72.5519 6.65782 69.2311 6.65782 65.9821V63.5454H10.3552V65.9821C10.3425 69.1761 12.1912 72.2558 16.3496 73.5207L16.3539 73.5165ZM21.9591 87.8913H18.3168V81.6177H4.44956V78.7325H21.9591V87.8956V87.8913ZM21.9591 77.5819H18.3168V62.3948H21.9591V77.5819Z"
          fill="url(#paint4_linear_5050_13255)"
        />
        <path
          d="M39.5826 79.5955L37.5943 82.4511C34.7219 81.1481 32.704 78.5549 31.5702 75.5048C30.4365 78.851 28.3636 81.698 25.4066 83.0983L23.3633 80.2386C27.5641 78.2503 29.7089 73.4742 29.6962 69.2861V67.8012H24.3447V64.8315H38.4362V67.8012H33.3089V69.2861C33.3089 73.1527 35.3945 77.6749 39.5826 79.5955ZM44.0372 72.1162H47.7896V75.1156H44.0372V87.8913H40.4244V62.3948H44.0372V72.1162Z"
          fill="url(#paint5_linear_5050_13255)"
        />
        <path
          d="M59.8044 79.3461L57.7315 82.0916C55.4217 80.8437 53.8946 78.7581 52.9977 76.1522C52.1305 79.1515 50.6287 81.5755 48.2047 83.0181L46.0176 80.243C49.8545 78.0432 51.1321 73.5463 51.1152 68.6432V67.886H46.8298V64.9712H58.5395V67.886H54.618V68.6432C54.618 73.0428 55.9336 77.3282 59.8002 79.3461H59.8044ZM68.7729 87.8619H65.3251V75.1157H63.1972V86.7408H59.8087V62.7884H63.1972V72.1756H65.3251V62.3992H68.7729V87.8661V87.8619Z"
          fill="url(#paint6_linear_5050_13255)"
        />
        <path
          d="M93.2916 76.4609H70.0117V73.8254H93.2916V76.4609ZM90.5164 83.826H76.2558V85.2263H91.244V87.663H72.643V81.4993H86.9587V80.209H72.6134V77.7427H90.5164V83.8218V83.826ZM90.4614 68.8377H76.4546V70.183H90.9395V72.6197H72.8968V66.5406H86.8783V65.28H72.8715V62.8433H90.4657V68.8377H90.4614Z"
          fill="url(#paint7_linear_5050_13255)"
        />
        <path
          d="M123.971 64.544C123.971 69.5443 119.838 73.102 114.393 73.0809C108.932 73.102 104.777 69.5443 104.777 64.544C104.777 59.5436 108.932 55.969 114.393 55.969C119.854 55.969 123.971 59.5648 123.971 64.544ZM135.317 84.8498L133.434 88.7333C127.761 87.828 123.725 85.3279 121.665 81.9266C119.55 85.349 115.493 87.828 109.82 88.7333L107.937 84.8498C115.4 83.7922 119.072 79.9468 119.089 76.0802V75.3864H124.241V76.0802C124.22 79.9257 127.837 83.7922 135.317 84.8498ZM109.663 64.544C109.642 67.2726 111.664 68.9097 114.393 68.9266C117.105 68.9055 119.106 67.2726 119.085 64.544C119.106 61.7731 117.105 60.1782 114.393 60.1993C111.664 60.1782 109.642 61.7773 109.663 64.544ZM133.586 77.1209H128.586V53.8538H133.586V77.1209Z"
          fill="url(#paint8_linear_5050_13255)"
        />
        <path
          d="M153.541 61.0834H142.042V76.3509C147.14 76.2917 151.311 76.0421 155.888 75.2721L156.388 79.3883C151.04 80.3105 146.311 80.5051 140.041 80.5051H137.08V57.0476H153.541V61.0876V61.0834ZM163.351 66.7775H168.503V70.8937H163.351V88.9321H158.389V53.8198H163.351V66.7817V66.7775Z"
          fill="url(#paint9_linear_5050_13255)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5050_13255"
          x1="21.4858"
          y1="22.828"
          x2="60.7819"
          y2="22.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B8CDB9" />
          <stop offset="1" stop-color="#168D8D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5050_13255"
          x1="154.489"
          y1="22.828"
          x2="115.489"
          y2="22.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#53ADAE" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5050_13255"
          x1="49.0882"
          y1="49.4286"
          x2="90.1991"
          y2="-1.96216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BCCCBA" />
          <stop offset="1" stop-color="#6AAEAB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5050_13255"
          x1="124.881"
          y1="1.51945"
          x2="84.9164"
          y2="34.0512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#53ADAE" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5050_13255"
          x1="-26.2631"
          y1="75.1409"
          x2="111.546"
          y2="75.1409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5050_13255"
          x1="-26.2634"
          y1="0.512616"
          x2="111.546"
          y2="0.512616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5050_13255"
          x1="-26.2629"
          y1="75.1284"
          x2="111.546"
          y2="75.1284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5050_13255"
          x1="-26.2635"
          y1="75.2552"
          x2="111.546"
          y2="75.2552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5050_13255"
          x1="-26.2632"
          y1="71.2957"
          x2="111.546"
          y2="71.2957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5050_13255"
          x1="-26.2638"
          y1="71.3717"
          x2="111.545"
          y2="71.3717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CA3A3" />
          <stop offset="1" stop-color="#035F64" />
        </linearGradient>
        <clipPath id="clip0_5050_13255">
          <rect
            width="168.006"
            height="88.4152"
            fill="white"
            transform="translate(0.498047 0.512695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileEatDaLogo;
