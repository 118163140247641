import React from 'react';
import styled from 'styled-components';

import ElectricBulb from '../../images/ElectricBulb';
import GraphIcon from '../../images/GraphIcon';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const Fourth = () => {
  return (
    <FourthContainer>
      <Wrapper>
        <MissionFirst url={`${cdnUrl}/asia_bg.png`}>
          <MissionBox textColor="#FFFFFF">
            <div>
              <ElectricBulb />
              <h1>Team Mission</h1>
              <span>
                세상을 새로운 관점으로 바라봄으로써 <br />
                기존의 번거로운 구조와 서비스를 <br />
                새롭게 개선하여 개발합니다.
              </span>
            </div>
          </MissionBox>
        </MissionFirst>
        <MissionFirst url={`${cdnUrl}/asia_bg_right.png`}>
          {/*컨텐츠 크기가 달라서 패딩으로 수평으로 맞춰놨습니다.*/}
          <MissionBox textColor="#4A3411">
            <div>
              <GraphIcon />
              <h1>Team Vision</h1>
              <span>
                당사는 최신 기술과 창의적인 아이디어를
                <br />
                바탕으로, 고객 맞춤형 솔루션을 제공하며,
                <br />
                다양한 산업 분야에서 경쟁력을
                <br /> 강화할 수 있도록 돕고 있습니다.
              </span>
            </div>
          </MissionBox>
        </MissionFirst>
      </Wrapper>
    </FourthContainer>
  );
};

const FourthContainer = styled.div`
  width: 100%;
  height: 100vh;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center; /* 상단에 맞추기 위해 변경 */
  justify-content: center;
`;
const MissionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.textColor};
  div {
    display: flex;
    gap: 36px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    h1 {
      text-align: center;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 44px;
    }
    span {
      text-align: center;
      line-height: 52px;
      font-size: 28px;
      font-weight: 700;
    }
  }
`;
const MissionFirst = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* 상단에 맞추기 위해 추가 */
  justify-content: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.url})`};
`;

export default Fourth;
