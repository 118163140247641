import React from 'react';
import styled from 'styled-components';
import MainLogo from '../../images/Mainlogo';
import BottomArrow from '../../images/BottomArrow';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const MobileFirst = () => {
  return (
    <FirstContainer>
      <FirstMain>
        <MainLogo />
        <MainText>
          세상의 모든 구조를 <br /> 다시 바라봅니다
        </MainText>
        <SubText>
          시장의 연결성을 찾아 구상하고 세상을 변화시키는
          <br />
          '아시아노베이트'입니다.
        </SubText>
        <Bottom>
          <BottomArrow />
        </Bottom>
      </FirstMain>
    </FirstContainer>
  );
};

export default MobileFirst;

export const FirstContainer = styled.div`
  width: 100%;
  height: 100%;
  background: cover;
  background-image: url('${cdnUrl}/first_mobile.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
`;

const FirstMain = styled.div`
  position: relative;
  bottom: 15%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const MainText = styled.div`
  color: white;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`;

const SubText = styled.div`
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 5%;
  display: flex;
  justify-content: center;
  width: 40px;
`;
