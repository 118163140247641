import React from 'react';

const MobileAsianobateFont = () => {
  return (
    <svg
      width="115"
      height="9"
      viewBox="0 0 115 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.23082 8.8003H7.43882L6.42224 6.23597H2.32632L1.2979 8.8003H0.5L3.9635 0.103376H4.80869L8.23673 8.8003H8.23082ZM6.16218 5.58198L4.38905 1.06715L2.57455 5.58198H6.16218Z"
        fill="#4A3411"
      />
      <path
        d="M14.7796 7.7963C15.1696 8.09461 15.607 8.2495 16.0917 8.25524C16.7182 8.26098 17.2324 8.11182 17.6225 7.80204C18.0126 7.49225 18.2135 7.07347 18.2135 6.55716C18.2135 6.21295 18.1189 5.92038 17.9298 5.69091C17.7466 5.46144 17.5102 5.27213 17.2324 5.14018C16.9546 5.00824 16.5763 4.84761 16.0976 4.66977C15.542 4.46898 15.0987 4.27967 14.7618 4.10756C14.4249 3.93546 14.1471 3.68878 13.9107 3.36752C13.6743 3.04627 13.562 2.63322 13.562 2.13986C13.562 1.70387 13.6802 1.32524 13.9166 0.998244C14.1531 0.671249 14.4722 0.424569 14.8859 0.252467C15.2938 0.0803639 15.7489 -0.00568756 16.2572 0.00578595C16.6532 0.0115227 17.0196 0.0861002 17.3565 0.229519C17.6934 0.372938 17.983 0.573725 18.2312 0.843352C18.4795 1.11298 18.6627 1.43424 18.7868 1.81286L18.1012 2.04233C17.9771 1.63502 17.7466 1.30229 17.3979 1.04414C17.0551 0.785984 16.665 0.654039 16.2394 0.648302C15.6779 0.648302 15.211 0.785985 14.8505 1.05561C14.4899 1.33098 14.3067 1.69239 14.3067 2.15133C14.3067 2.51275 14.4013 2.8168 14.5904 3.05774C14.7796 3.29868 15.0219 3.488 15.3056 3.63141C15.5893 3.77483 15.9735 3.92973 16.4522 4.10756C16.9901 4.30261 17.4215 4.48619 17.7525 4.65829C18.0835 4.8304 18.3672 5.07134 18.5977 5.38112C18.8282 5.69091 18.9464 6.09248 18.9464 6.58011C18.9464 7.03905 18.8282 7.44636 18.5859 7.80204C18.3494 8.15771 18.0066 8.43308 17.5752 8.62813C17.1437 8.82318 16.6413 8.91497 16.068 8.90349C15.4652 8.89776 14.9155 8.72565 14.4308 8.39866C13.9403 8.06593 13.5206 7.60125 13.166 6.99889L13.8102 6.66042C14.0585 7.1251 14.3777 7.50946 14.7736 7.80204L14.7796 7.7963Z"
        fill="#4A3411"
      />
      <path
        d="M26.0572 0.0975447V8.79447H25.3125V0.0975447H26.0572Z"
        fill="#4A3411"
      />
      <path
        d="M40.1234 8.8003H39.3314L38.3148 6.23597H34.2189L33.1905 8.8003H32.3926L35.8561 0.103376H36.7013L40.1293 8.8003H40.1234ZM38.0488 5.58198L36.2757 1.06715L34.4612 5.58198H38.0488Z"
        fill="#4A3411"
      />
      <path
        d="M52.7463 8.8003H52.0016L46.9127 1.38841V8.8003H46.168V0.103376H46.9127L52.0016 7.48658V0.103376H52.7463V8.8003Z"
        fill="#4A3411"
      />
      <path
        d="M65.573 0.567939C66.2409 0.946564 66.7551 1.46861 67.1274 2.14555C67.4998 2.82248 67.683 3.59121 67.683 4.44599C67.683 5.30076 67.4998 6.06949 67.1274 6.74642C66.7551 7.42336 66.235 7.94541 65.573 8.32403C64.911 8.70266 64.1486 8.89197 63.3093 8.89197C62.47 8.89197 61.7076 8.70266 61.0456 8.32403C60.3777 7.94541 59.8576 7.42336 59.4853 6.74642C59.1129 6.06949 58.9297 5.30076 58.9297 4.44599C58.9297 3.59121 59.1129 2.81675 59.4853 2.14555C59.8576 1.47435 60.3777 0.946564 61.0456 0.567939C61.7076 0.189313 62.4641 0 63.3093 0C64.1545 0 64.9051 0.189313 65.573 0.567939ZM65.1888 7.76757C65.7385 7.44057 66.164 6.98737 66.4714 6.41369C66.7787 5.83428 66.9324 5.18029 66.9324 4.45172C66.9324 3.72315 66.7787 3.06343 66.4714 2.48975C66.164 1.91034 65.7385 1.45714 65.1888 1.13588C64.6392 0.808883 64.0126 0.648253 63.3093 0.648253C62.606 0.648253 61.9795 0.808883 61.4298 1.13588C60.8801 1.46287 60.4546 1.91034 60.1472 2.48975C59.8399 3.06916 59.6862 3.72315 59.6862 4.45172C59.6862 5.18029 59.8399 5.84002 60.1472 6.41369C60.4546 6.9931 60.8801 7.44631 61.4298 7.76757C61.9795 8.09456 62.606 8.25519 63.3093 8.25519C64.0126 8.25519 64.6392 8.09456 65.1888 7.76757Z"
        fill="#4A3411"
      />
      <path
        d="M76.8917 8.8003H76.0997L72.6953 0.103376H73.4932L76.4839 7.76768L79.3386 0.103376H80.1306L76.8917 8.8003Z"
        fill="#4A3411"
      />
      <path
        d="M91.9808 8.8003H91.1829L90.1663 6.23597H86.0704L85.042 8.8003H84.25L87.7135 0.103376H88.5587L91.9867 8.8003H91.9808ZM89.9122 5.58198L88.1391 1.06715L86.3305 5.58198H89.9181H89.9122Z"
        fill="#4A3411"
      />
      <path
        d="M103.016 0.740156H100.274V8.8003H99.5293V0.740156H96.8105V0.103376H103.016V0.740156Z"
        fill="#4A3411"
      />
      <path
        d="M114.5 0.740156H109.784V3.9929H113.868V4.64689H109.784V8.16352H114.5V8.8003H109.039V0.103376H114.5V0.740156Z"
        fill="#4A3411"
      />
    </svg>
  );
};

export default MobileAsianobateFont;
